<template>
  <el-dialog v-model="visible" title="手动发放" width="500">
    <el-form ref="formRef" :model="model" :rules="rules">
      <el-form-item label="选择用户" prop="userIds">
        <el-button type="primary" link @click="selectUser">请选择用户，已选：【{{uiModel.users.length}}】人</el-button>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="visible = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="submit">确认发放</el-button>
    </template>
    <select-user-drawer @confirm="confirmUserEvent" ref="selectUserDrawerRef"/>
  </el-dialog>
</template>

<script lang="ts" setup name="send-coupon-modal">
  import {ref, defineExpose, defineEmits} from "vue";
  import {useApp} from "@hview/hview-vue3/packages/hooks/use-app";
  import {SelectUserDrawer} from "@/components/views/coupon";
  import {adminDistributeCouponsApi} from "@/api/kh";
  import {Notice} from "@hview/hview-vue3/packages";
  const formRef = ref();
  const emits = defineEmits(["change"]);

  const {visible, loading} = useApp();

  const rules = {
    userIds: [{
      required: true, message: "请选择用户", trigger: ["blur", "change"]
    }]
  }

  const selectUserDrawerRef = ref();

  const model = ref({
    id: "",
    userIds: ""
  });

  const uiModel = ref({
    users: []
  });

  const selectUser = () => {
    selectUserDrawerRef.value.open(uiModel.value.users);
  }

  const confirmUserEvent = (value: any) => {
    uiModel.value.users = value;
    model.value.userIds = value.join(",");
  }

  const open = (id: any) => {
    model.value.id = id;
    uiModel.value.users = [];
    visible.value = true;
  }

  /**
   * @description 提交
   */
  const submit = () => {
    formRef.value.validate((valid: boolean) => {
      if (valid) {
        loading.value = true;
        adminDistributeCouponsApi(model.value).then(() => {
          Notice.success("发送成功");
          emits("change");
          visible.value = false;
        }).finally(() => {
          loading.value = false;
        })
      }
    });

  }

  defineExpose({
    open
  });
</script>

<style scoped>

</style>