"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApplication = exports.getSocketPath = exports.getApiPath = exports.prod = exports.development = void 0;
/**
 * @description 开发环境配置
 */
var development = {
    server: {
        // 服务端url
        baseurl: 'https://crqyy.carecancer.cn',
        // socket url
        socketurl: "wss://crqyy.carecancer.cn",
        // 普通二维码路径
        qrcodePrefix: "/testweixin"
    },
    microApps: [{
            url: "http://localhost:9003/",
            name: "doctor",
            path: "/doctor",
            description: "医生端后台管理系统"
        }]
};
exports.development = development;
/**
 * @description 生产环境配置
 */
var prod = {
    server: {
        // 服务端url
        baseurl: 'https://crqyy.carecancer.cn',
        // socket url
        socketurl: "wss://crqyy.carecancer.cn",
        // 普通二维码路径
        qrcodePrefix: "/testweixin"
    },
    microApps: [{
            url: "https://crqyy-doctor.carecancer.cn",
            name: "doctor",
            path: "/doctor",
            description: "医生端后台管理系统"
        }]
};
exports.prod = prod;
// const prod = {
//     server: {
//         baseurl: 'https://app.carecancer.cn',
//         socketurl: "wss://app.carecancer.cn"
//     },
//     microApps: [{
//         url: "http://doctor.carecancer.cn",
//         name: "doctor",
//         path: "/doctor",
//         description: "医生管理后端"
//     }]
// }
/**
 * @description 获取api path
 */
var getApiPath = function () {
    return process.env.NODE_ENV === 'development' ? development.server.baseurl : prod.server.baseurl;
};
exports.getApiPath = getApiPath;
var getApplication = function () {
    return process.env.NODE_ENV === 'development' ? development.server : prod.server;
};
exports.getApplication = getApplication;
/**
 * @description 获取socket url
 */
var getSocketPath = function () {
    return process.env.NODE_ENV === 'development' ? development.server.socketurl : prod.server.socketurl;
};
exports.getSocketPath = getSocketPath;
