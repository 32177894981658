<template>
  <h-container isBack :title="model.id ? '修改优惠券' : '创建优惠券'">
    <el-form ref="formRef" :rules="rules" :model="model" label-width="110">
      <el-form-item label="券名称" prop="name">
        <el-input v-model="model.name"/>
      </el-form-item>
      <el-form-item label="类型" prop="coupon_type">
        <el-radio-group v-model="model.coupon_type" @change="changeCouponType">
          <h-dict dictKey="coupon_type_1"/>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="model.coupon_type == 'exclusive'" label="" prop="limit_user_ids">
        <el-button type="primary" @click="selectUser" link>选择用户【已选：{{uiModel.users.length}}人】</el-button>
      </el-form-item>

      <el-form-item label="优惠类型" prop="type">
        <el-radio-group @change="changeTypeEvent" v-model="model.type">
          <h-dict dictKey="coupon_type_2"/>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="model.type == 'cash'" label="" prop="amount">
        <div class="flex align-items-center">
          <span class="w-40 m-l-10 font-color-main">立减</span>
          <el-input v-model="model.amount" class="w-120">
            <template #append>元</template>
          </el-input>
        </div>
      </el-form-item>

      <el-form-item v-if="model.type == 'discount'" label="" prop="amount">
        <div class="flex align-items-center">
          <span class="w-40 font-color-main">优惠</span>
          <el-input v-model="model.amount" type="number" class="w-120">
            <template #append>%</template>
          </el-input>
        </div>
      </el-form-item>

      <el-form-item label="发放数量" prop="grant">
        <el-input v-model="model.grant" type="number" class="w-140" placeholder="数量">
          <template #append>张</template>
        </el-input>
      </el-form-item>
      <el-form-item label="每人限领张数" prop="limit">
        <el-input v-model="model.limit" type="number" placeholder="张数" class="w-140">
          <template #append>张</template>
        </el-input>
      </el-form-item>
      <el-form-item label="领券时间" prop="gettimestart">
        <div class="w-200">
          <el-date-picker
              v-model="uiModel.getTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              size="default"
              @change="changeGetTimeEvent"
              value-format="x"
          />
        </div>
      </el-form-item>
      <el-form-item label="使用时间" prop="usetimestart">
        <div class="w-200">
          <el-date-picker
              v-model="uiModel.useTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              size="default"
              @change="changeUseTimeEvent"
              value-format="x"
          />
        </div>
      </el-form-item>
      <el-form-item label="积分" prop="payScore">
        <el-input placeholder="积分" v-model="model.payScore" class="w-120"/>
      </el-form-item>
      <el-form-item label="可用范围" prop="coupon_use_range">
        <el-radio-group v-model="model.coupon_use_range">
          <h-dict dictKey="coupon_use_range"/>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="" v-if="model.coupon_use_range == 'part'" prop="goods_ids">
        <el-button type="primary" link @click="selectGoods">选择商品【已选：{{uiModel.goods.length}}】</el-button>
      </el-form-item>

      <el-form-item label="优惠券内容" prop="description">
        <el-input v-model="model.description" placeholder="请输入优惠券内容"/>
      </el-form-item>
<!--      <el-form-item label="状态" prop="status">-->
<!--        <el-radio-group v-model="model.status">-->
<!--          <h-dict dictKey="status_type"/>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->
    </el-form>
    <select-user-drawer ref="selectUserDrawerRef" @confirm="confirmUserEvent"/>
    <select-goods-drawer ref="selectGoodsDrawerRef" @confirm="confirmGoodsEvent"/>
    <template #footer>
      <div class="h-button-wrapper">
        <el-button type="primary" v-if="model.id" size="large" @click="update">修改</el-button>
        <el-button type="primary" v-else size="large" @click="submit">保存</el-button>
      </div>
    </template>
  </h-container>
</template>

<script lang="ts" setup>
  import {SelectUserDrawer, SelectGoodsDrawer} from "@/components/views/coupon";
  import {ref, onMounted} from "vue";
  import {useRouter, useRoute} from "vue-router";
  import {adminCreateCouponsApi, adminUpdateCouponsApi, getCouponsInfoApi} from "@/api/kh";
  import {useApp} from "@hview/hview-vue3/packages/hooks";
  import {Notice} from "@hview/hview-vue3/packages";
  const {loading} = useApp();
  const router = useRouter();
  const route = useRoute();
  const selectUserDrawerRef = ref();
  const selectGoodsDrawerRef = ref();
  const formRef = ref();
  const uiModel = ref({
    users: [],
    goods: [],
    useTime: "",
    getTime: ""
  });

  /**
   * 表单数据
   */
  const model = ref<any>({
    id: "",
    // 代金券类型
    coupon_type: "",
    // 使用范围
    coupon_use_range: "",
    // 类型
    type: "",
    // 限制张数
    limit: "",
    // 用户id
    limit_user_ids: "",
    // 商品列表
    goods_ids: "",
    name: "",
    status: "",
    // 使用开始时间
    usetimestart: "",
    // 使用结束时间
    usetimeend: "",
    // 领取开始时间
    gettimestart: "",
    // 领取结束时间
    gettimeend: "",
    // 发放数量
    grant: "",
    // 积分
    payScore: "",
    // 描述
    description: "",
    // 券面额
    amount: "",
    // 消费门槛
    enough: ""
  });

  /**
   * @description 校验规则
   */
  const rules = {
    name: [{
      required: true, message: "请输入券名称", trigger: ["blur", "change"]
    }],
    coupon_type:[{
      required: true, message: "请选择类型", trigger: ["blur", "change"]
    }],
    type:[{
      required: true, message: "请选择优惠类型", trigger: ["blur", "change"]
    }],
    amount:[{
      required: true, message: "请输入优惠金额", trigger: ["blur", "change"]
    }],
    grant:[{
      required: true, message: "请输入发放数量", trigger: ["blur", "change"]
    }],
    limit:[{
      required: true, message: "请输入限领张数", trigger: ["blur", "change"]
    }],
    gettimestart:[{
      required: true, message: "请选择领取时间", trigger: ["blur", "change"]
    }],
    usetimestart:[{
      required: true, message: "请选择使用时间", trigger: ["blur", "change"]
    }],
    payScore:[{
      required: true, message: "请输入积分", trigger: ["blur", "change"]
    }],
    coupon_use_range:[{
      required: true, message: "请选择范围", trigger: ["blur", "change"]
    }],
    limit_user_ids: [{
      required: true, message: "请选择用户", trigger: ["blur", "change"]
    }],
    goods_ids: [{
      required: true, message: "请选择商品", trigger: ["blur", "change"]
    }],
    status: [{
      required: true, message: "请选择状态", trigger: ["blur", "change"]
    }]
  }

  /**
   * @description 领取时间事件
   */
  const changeGetTimeEvent = (dateRange: any) => {
    if (dateRange) {
      model.value.gettimestart = dateRange[0] / 1000;
      model.value.gettimeend = dateRange[1] / 1000;
    } else {
      model.value.gettimestart = "";
      model.value.gettimeend = "";
    }

  }

  /**
   * @description 使用时间事件
   */
  const changeUseTimeEvent = (dateRange: any) => {
    if (dateRange) {
      model.value.usetimestart = dateRange[0] / 1000;
      model.value.usetimeend = dateRange[1] / 1000;
    } else {
      model.value.usetimestart = "";
      model.value.usetimeend = "";
    }

  }
  /**
   * @description 优惠类型变更
   */
  const changeCouponType = () => {
    uiModel.value.users = []
  }
  /**
   * @description 优惠类型变更
   */
  const changeTypeEvent = (e) => {
    model.value.amount = "";
  }


  /**
   * @description 打开选择用户
   */
  const selectUser = () => {
    selectUserDrawerRef.value.open(uiModel.value.users);
  }

  /**
   * @description 打开选择商品
   */
  const selectGoods = () => {
    selectGoodsDrawerRef.value.open(uiModel.value.goods);
  }

  /**
   * @description 选中用户
   * @param data
   */
  const confirmUserEvent = (data: any) => {
    uiModel.value.users = data;
    model.value.limit_user_ids = data.join(",");
  }

  /**
   * @description
   * @param data
   */
  const confirmGoodsEvent = (data: any) => {
    uiModel.value.goods = data;
    model.value.goods_ids = data.join(",");
  }
  const update = () => {
    formRef.value.validate((valid: boolean) => {
      if (valid) {
        loading.value = true;
        adminUpdateCouponsApi(model.value).then(() => {
          Notice.success("修改成功");
          router.back();
        }).finally(() => {
          loading.value = false;
        });
      }
    });
  }

  const submit = () => {
    formRef.value.validate((valid: boolean) => {
      if (valid) {
        loading.value = true;
        adminCreateCouponsApi(model.value).then(() => {
          Notice.success("创建成功");
          router.back();
        }).finally(() => {
          loading.value = false;
        });
      }
    });
  }

  const load = () => {
    loading.value = true;
    getCouponsInfoApi({id: model.value.id}).then((res: any) => {
      const {data} = res;
      model.value.name = data.name;
      model.value.coupon_type = data.coupon_type;
      model.value.limit_user_ids = data.limit_user_ids;
      if (data.limit_user_ids) {
        uiModel.value.users = data.limit_user_ids.split(",");
      }
      model.value.type = data.type;
      model.value.amount = data.amount;
      model.value.grant = data.grant;
      model.value.limit = data.limit;
      model.value.gettimestart = data.gettimestart;
      model.value.gettimeend = data.gettimeend;
      uiModel.value.getTime = [data.gettimestart * 1000, data.gettimeend * 1000];

      model.value.usetimestart = data.usetimestart;
      model.value.usetimeend = data.usetimeend;
      uiModel.value.useTime = [data.usetimestart * 1000, data.usetimeend * 1000];
      model.value.payScore = data.pay_score;
      model.value.goods_ids = data.goods_ids;
      model.value.description = data.description;
      const goodsIds = data.goods_ids;
      if (goodsIds) {
        model.value.coupon_use_range = "part";
        uiModel.value.goods = goodsIds.split(",");
      } else {
        model.value.coupon_use_range = "all";
      }
      console.info(data);
    }).finally(() => {
      loading.value = false;
    });
  }

  onMounted(() => {
    const {query} = route;
    if (query.id) {
      model.value.id = query.id;
      load();
    }
  });
</script>

<style scoped>

</style>