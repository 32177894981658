<!--
  @description: 预览文章
-->
<template>
  <el-dialog :close-on-click-modal="false" v-model="visible" :title="uiModel.title" width="500">
    <div class="preview-article p-10" v-if="uiModel.html" v-loading="loading" v-html="uiModel.html"></div>
    <div v-else>
      <el-empty description="暂无文章内容，快去创建吧" />
    </div>
    <template #footer>
      <el-button @click="visible = false" type="primary">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup name="PreviewArticleDialog">
  import {defineExpose, ref} from "vue";
  import {useApp} from "@hview/hview-vue3/packages/hooks";
  import {getArticleInfoApi} from "@/api/doctor";
  const {loading, visible} = useApp();

  const uiModel = ref({
    title: "",
    html: ""
  });


  /**
   * @description 预览
   * @param data
   */
  const preview = (data: any) => {
    visible.value = true;
    loading.value = true;
    getArticleInfoApi({
      id: data.id
    }).then((res: any) => {

      const data = res.data;
      uiModel.value.html = data.content_fmt;
      uiModel.value.title = data.title;
    }).finally(() => {
      loading.value = false;
    });
  }

  defineExpose({
    preview
  });


</script>

<style lang="scss">
.preview-article {
  //background: var(--background-color);
  border: solid 1px var(--line-color-2);
  height: calc(100vh - 300px);
  overflow: scroll;
}
</style>