"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Message = exports.Notice = void 0;
// @ts-ignore
var element_plus_1 = require("element-plus");
/**
 * @description 通知提示
 */
var Notice = /** @class */ (function () {
    function Notice() {
    }
    /**
     * @description 成功提示
     * @param message
     */
    Notice.success = function (message) {
        if (message) {
            (0, element_plus_1.ElNotification)({
                type: "success",
                title: "系统提示",
                message: message
            });
        }
    };
    /**
     * @description 警告提示
     * @param message
     */
    Notice.warning = function (message) {
        if (message) {
            (0, element_plus_1.ElNotification)({
                type: "warning",
                title: "系统提示",
                message: message
            });
        }
    };
    /**
     * @description 消息提示
     * @param message
     */
    Notice.info = function (message) {
        if (message) {
            (0, element_plus_1.ElNotification)({
                type: "info",
                title: "系统提示",
                message: message
            });
        }
    };
    /**
     * @description 错误提示
     * @param message
     */
    Notice.error = function (message) {
        if (message) {
            (0, element_plus_1.ElNotification)({
                type: "error",
                title: "系统提示",
                message: message
            });
        }
    };
    return Notice;
}());
exports.Notice = Notice;
/**
 * @description 消息提示
 */
var Message = /** @class */ (function () {
    function Message() {
    }
    /**
     * @description 成功提示
     * @param message
     */
    Message.success = function (message) {
        if (message) {
            (0, element_plus_1.ElMessage)({
                type: "success",
                message: message
            });
        }
    };
    /**
     * @description 警告提示
     * @param message
     */
    Message.warning = function (message) {
        if (message) {
            (0, element_plus_1.ElMessage)({
                type: "warning",
                message: message
            });
        }
    };
    /**
     * @description 消息提示
     * @param message
     */
    Message.info = function (message) {
        if (message) {
            (0, element_plus_1.ElMessage)({
                type: "info",
                message: message
            });
        }
    };
    /**
     * @description 错误提示
     * @param message
     */
    Message.error = function (message) {
        if (message) {
            (0, element_plus_1.ElMessage)({
                type: "error",
                message: message
            });
        }
    };
    return Message;
}());
exports.Message = Message;
