<!--
  @description: 文章-弹窗
  @author zhaxianhe
  @version v1.0
-->
<template>
  <el-dialog :close-on-click-modal="false" v-model="visible" :title="uiModel.title" width="500">
    <el-form v-loading="loading" ref="formRef" :model="model" :rules="rules" label-width="auto">
      <el-form-item label="文章名称" prop="title">
        <el-input placeholder="请输入文章名称" v-model="model.title"/>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="model.status" class="ml-4">
          <el-radio value="normal" size="large">启用</el-radio>
          <el-radio value="hidden"  size="large">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <!-- 创建 -->
      <div v-if="uiModel.type == 'ADD'">
        <el-button link @click="visible = false">暂不创建</el-button>
        <el-button type="primary" :loading="loading" @click="submit">
          下一步<el-icon><Right/></el-icon>
        </el-button>
      </div>

      <!-- 修改 -->
      <div v-if="uiModel.type == 'UPDATE'">
        <el-button link @click="toEditPage">跳过此步</el-button>
        <el-button type="primary" plain @click="updateForm('close')">修改并关闭</el-button>
        <el-button type="primary" :loading="loading" @click="updateForm">
          继续<el-icon><Right/></el-icon>
        </el-button>
      </div>

    </template>
  </el-dialog>
</template>

<script lang="ts" setup name="ArticleDialog">
  import {Right } from '@element-plus/icons-vue';
  import {ref, defineExpose, reactive, watch, defineEmits} from "vue";
  import {useRouter} from "vue-router";
  import type { FormInstance, FormRules } from 'element-plus'
  import {addArticleApi, updateArticleApi} from "@/api/doctor";
  import {Notice} from "@hview/hview-vue3/packages";
  const router = useRouter();
  const formRef = ref<FormInstance>();
  const visible = ref<boolean>(false);
  const loading = ref<boolean>(false);
  const emit = defineEmits(["change"]);
  const uiModel = ref({
    title: "",
    type: ""
  });

  let model = reactive<RuleForm>({
    id: "",
    // 名称
    title: "",
    // 状态
    status: "normal"
  });

  interface RuleForm {
    id?: string;
    title: string;
    status: string;
  }


  const rules = reactive<FormRules<RuleForm>>({
    title: [
      { required: true, message: "请输入文章名称", trigger: ["change", "blur"] },
    ],
    status: [
      { required: true, message: "请选择状态", trigger: ["change", "blur"] },
    ]
  });

  watch(visible, (value) => {
    if (!value) {
      formRef.value.resetFields();
    }
  })

  /**
   * @description 创建文章
   * @param options
   */
  const create = () => {
    uiModel.value.type = "ADD";
    uiModel.value.title = "创建文章";
    visible.value = true;
  }

  /**
   * @description 修改文章
   * @param id
   */
  const update = (data: any) => {
    uiModel.value.type = "UPDATE";
    uiModel.value.title = "修改文章";
    visible.value = true;
    model.id = data.id;
    model.title = data.title;
    model.status = data.status;
  }

  /**
   * @description 跳转到修改页面
   */
  const toEditPage = () => {
    router.push(`/article/${model.id}`);
  }

  const updateForm = (type?: string) => {
    formRef.value.validate((valid: boolean) => {
      if (valid) {
        loading.value = true;
        updateArticleApi(model).then(() => {
          Notice.success("文章修改成功");
          if (type == "close") {
            visible.value = false;
            emit("change");
          } else {
            toEditPage();
          }
        }).finally(() => {
          loading.value = false;
        });
      }
    });
  }


  /**
   * @description 提交表单
   */
  const submit = () => {
    formRef.value.validate((valid: boolean) => {
      if (valid) {
        loading.value = true;
        addArticleApi(model).then((res: any) => {
          Notice.success("文章创建成功");
          const id = res.data;
          router.push(`/article/${id}`);
        }).finally(() => {
          loading.value = false;
        });
      }
    });
  }
  defineExpose({
    create,
    update
  });
</script>

<style scoped>

</style>