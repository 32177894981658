"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @description 字典缓存方法
 */
var storage_1 = require("../storage");
var CACKE_KEYS = {
    DICT: "dict"
};
/**
 * @description 字典缓存类
 */
var DictCache = /** @class */ (function () {
    function DictCache() {
    }
    DictCache.setDict = function (data) {
        storage_1.localStorage.set(CACKE_KEYS.DICT, data);
    };
    DictCache.getDict = function () {
        return storage_1.localStorage.get(CACKE_KEYS.DICT);
    };
    DictCache.remove = function () {
        storage_1.localStorage.remove(CACKE_KEYS.DICT);
    };
    return DictCache;
}());
exports.default = DictCache;
