"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var packages_1 = require("@hview/hview-vue3/packages");
var coupon = [{
        path: '/coupon',
        component: packages_1.HRouterView,
        children: [{
                path: "",
                meta: {
                    title: "优惠券管理"
                },
                component: function () { return Promise.resolve().then(function () { return require("@/views/coupon/list.vue"); }); }
            }, {
                path: "record",
                meta: {
                    title: "领取记录"
                },
                component: function () { return Promise.resolve().then(function () { return require("@/views/coupon/record.vue"); }); }
            }, {
                path: "edit",
                meta: {
                    title: ""
                },
                component: function () { return Promise.resolve().then(function () { return require("@/views/coupon/edit.vue"); }); }
            }]
    }];
exports.default = coupon;
