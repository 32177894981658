"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var article = [{
        path: '/article',
        meta: {
            title: "文章管理"
        },
        component: function () { return Promise.resolve().then(function () { return require("@/views/article/list.vue"); }); }
    }, {
        path: '/article/:id',
        meta: {
            title: "编辑",
            activeMenu: "/article"
        },
        component: function () { return Promise.resolve().then(function () { return require("@/views/article/edit.vue"); }); }
    }];
exports.default = article;
