<template>
  <h-container isTable title="优惠券管理">
    <template #extra-header>
      <div class="flex-1 text-align-right">
        <el-button type="primary" @click="create">创建优惠券</el-button>
      </div>
    </template>
<!--    <div>-->
<!--      <el-row :gutter="20">-->
<!--        <el-col :span="6">-->
<!--          <el-card>-->
<!--            <div>1000</div>-->
<!--            <div>总发券量/张</div>-->
<!--          </el-card>-->
<!--        </el-col>-->
<!--        <el-col :span="6">-->
<!--          <el-card>-->
<!--            <div>10</div>-->
<!--            <div>已过期/张</div>-->
<!--          </el-card>-->
<!--        </el-col>-->
<!--        <el-col :span="6">-->
<!--          <el-card>-->
<!--            <div>10</div>-->
<!--            <div>已使用/张</div>-->
<!--          </el-card>-->
<!--        </el-col>-->
<!--        <el-col :span="6">-->
<!--          <el-card>-->
<!--            <div>10%</div>-->
<!--            <div>使用率</div>-->
<!--          </el-card>-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--    </div>-->
    <div class="m-t-15">
      <el-form ref="formRef" :model="model" :inline="true" label-width="auto">
        <el-form-item label="名称" prop="name">
          <el-input v-model="model.name"  placeholder="请输入优惠券名称"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :loading="loading" :data="list" stripe>
        <el-table-column prop="id" label="编号" header-align="center" align="center" width="100" />
        <el-table-column prop="name" label="优惠券名称" align="center" header-align="center" width="180" />
        <el-table-column prop="type" label="类型" align="center" header-align="center" width="180">
          <template #default="scope">
            <h-dict v-model="scope.row.type" mode="text" dictKey="coupon_type_2"/>
          </template>
        </el-table-column>
        <el-table-column prop="coupon_type" label="券类型" align="center" header-align="center" width="180">
          <template #default="scope">
            <h-dict v-model="scope.row.coupon_type" mode="text" dictKey="coupon_type_1"/>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="可用范围" align="center" header-align="center" width="180">
          <template #default="scope">
            <span v-if="scope.row.goods_ids">全部商品</span>
            <span v-else>部分商品</span>
          </template>
        </el-table-column>

        <el-table-column prop="description" label="优惠内容" align="center" header-align="center" width="180" />
        <el-table-column prop="stock" label="数量（张）" align="center" header-align="center" width="180" />
        <el-table-column prop="title" label="状态" align="center" header-align="center"  width="180">
          <template #default="scope">
            <h-dict v-model="scope.row.type" mode="text" dictKey="status_type"/>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="操作" width="280" align="center" header-align="center" fixed="right">
          <template #default="scope">
            <el-button type="primary" link @click="sendCoupon(scope.row)">手动发放</el-button>
            <el-button type="primary" link @click="toRecord(scope.row)">领取记录</el-button>
            <el-button type="primary" link @click="toUpdate(scope.row)">修改</el-button>
<!--            <el-button type="primary" link>删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <send-coupon-modal @change="load" ref="sendCouponModalRef"/>
    <template #footer>
      <div class="h-pagination">
        <h-pagination @change="paginationChangeEvent" :current-page="page" :total="totalRow" />
      </div>
    </template>
  </h-container>
</template>

<script lang="ts" setup>
import {ref, onMounted} from "vue";
import {useRouter} from "vue-router";
import {useApp} from "@hview/hview-vue3/packages/hooks/use-app";
import {usePagination} from "@hview/hview-vue3/packages";
import {getCouponsListApi} from "@/api/kh";
import {SendCouponModal} from "@/components/views/coupon";
const {updatePage, getPagination, totalRow, update, reload, page} = usePagination();
const {loading} = useApp();
const formRef = ref();
const router = useRouter();

const model = ref({
  name: ""
});

const list = ref();

const sendCouponModalRef = ref();
/**
 * @description 页码改变事件
 */
const paginationChangeEvent = (value: number) => {
  update(value, load);
}

/**
 * @description 加载数据
 */
const load = () => {
  loading.value = true;
  getCouponsListApi({...model.value, ...getPagination()}).then((res: any) => {
    const data = res.data;
    updatePage(data);
    list.value = data.data;
  }).finally(() => {
    loading.value = false;
  });
}
/**
 * @description 重置
 */
const resetForm = () => {
  formRef.value.resetFields();
  reload(load);
}

/**
 * @description 跳转到创建页面
 */
const create = () => {
  router.push("/coupon/edit")
}

const toUpdate = (data: any) => {
  router.push(`/coupon/edit?id=${data.id}`);
}

/**
 * @description 跳转到记录
 * @param data
 */
const toRecord = (data: any) => {
  router.push(`/coupon/record?id=${data.id}`);
}

const sendCoupon = (data: any) => {
  sendCouponModalRef.value.open(data.id);
}
onMounted(() => {
  load();
});
</script>

<style scoped>

</style>