"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @ts-ignore
var vue_router_1 = require("vue-router");
// @ts-ignore
var micro_1 = require("@hview/hview-utils/lib/cache/micro");
// @ts-ignore
var micro_utils_1 = require("@hview/hview-utils/lib/tools/micro-utils");
// @ts-ignore
var use_app_1 = require("@hview/hview-vue3/packages/hooks/use-app");
var routes_1 = require("@/router/routes");
var fixBugForVueRouter4 = (0, use_app_1.useApp)().fixBugForVueRouter4;
var router = (0, vue_router_1.createRouter)({
    history: (0, vue_router_1.createWebHistory)(window.__MICRO_APP_BASE_ROUTE__ || process.env.BASE_URL),
    routes: routes_1.default
});
fixBugForVueRouter4(router);
router.beforeEach(function (to, from, next) {
    micro_utils_1.default.setGlobalData({
        type: micro_1.MicroMessageType.ROUTE,
        data: {
            to: to,
            from: from,
            routes: routes_1.default
        }
    });
    next();
});
exports.default = router;
