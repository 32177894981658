"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @description: 用户缓存
 * @author zhaxianhe
 * @version v1.0
 */
var storage_1 = require("../storage");
var CACKE_KEYS = {
    TOKEN: "token",
    APPID: "appid",
    USERINFO: "userinfo",
    // 租户信息
    TENANT: "tenant",
    DEFAULTTENANT: "defaulttenant"
};
var UserCache = /** @class */ (function () {
    function UserCache() {
    }
    /**
     * @description 设置token
     * @param token 用户token
     */
    UserCache.setToken = function (token) {
        storage_1.localStorage.set(CACKE_KEYS.TOKEN, token);
    };
    /**
     * @description 获取token
     * @return string
     */
    UserCache.getToken = function () {
        return storage_1.localStorage.get(CACKE_KEYS.TOKEN);
    };
    /**
     * @description 删除token
     */
    UserCache.removeToken = function () {
        storage_1.localStorage.remove(CACKE_KEYS.TOKEN);
    };
    /**
     * @description 设置用户信息
     * @param userInfo
     */
    UserCache.setUserInfo = function (userInfo) {
        storage_1.localStorage.set(CACKE_KEYS.USERINFO, userInfo);
    };
    /**
     * @description 获取用户信息
     */
    UserCache.getUserInfo = function () {
        return storage_1.localStorage.get(CACKE_KEYS.USERINFO);
    };
    /**
     * @description 删除用户信息
     */
    UserCache.removeUserInfo = function () {
        storage_1.localStorage.remove(CACKE_KEYS.USERINFO);
    };
    /**
     * @description 设置应用id
     * @param id
     */
    UserCache.setAppId = function (id) {
        storage_1.localStorage.set(CACKE_KEYS.APPID, id);
    };
    /**
     * @description 获取应用id
     */
    UserCache.getAppId = function () {
        return storage_1.localStorage.get(CACKE_KEYS.APPID);
    };
    /**
     * @description 删除appid
     */
    UserCache.removeAppId = function () {
        storage_1.localStorage.remove(CACKE_KEYS.APPID);
    };
    /**
     * @description 设置默认租户
     * @param tenant
     */
    UserCache.setDefaultTenant = function (tenant) {
        storage_1.localStorage.set(CACKE_KEYS.DEFAULTTENANT, tenant);
    };
    /**
     * @description 获取默认租户
     */
    UserCache.getDefaultTenant = function () {
        return storage_1.localStorage.get(CACKE_KEYS.DEFAULTTENANT) || null;
    };
    /**
     * @description 删除默认租户
     */
    UserCache.removeDefaultTenant = function () {
        storage_1.localStorage.remove(CACKE_KEYS.DEFAULTTENANT);
    };
    /**
     * @description 设置租户信息
     * @param tenant
     */
    UserCache.setTenant = function (tenant) {
        storage_1.localStorage.set(CACKE_KEYS.TENANT, tenant);
    };
    /**
     * @description 获取租户信息
     */
    UserCache.getTenant = function () {
        return storage_1.localStorage.get(CACKE_KEYS.TENANT) || [];
    };
    /**
     * @description 获取team
     */
    UserCache.getTeam = function () {
        return storage_1.localStorage.get(CACKE_KEYS.DEFAULTTENANT)[1] || null;
    };
    /**
     * @description 删除租户信息
     */
    UserCache.removeTenant = function () {
        storage_1.localStorage.remove(CACKE_KEYS.TENANT);
    };
    /**
     * @description 清空用户所有缓存
     */
    UserCache.clearAll = function () {
        this.removeAppId();
        this.removeToken();
        this.removeUserInfo();
        this.removeTenant();
        this.removeDefaultTenant();
    };
    return UserCache;
}());
exports.default = UserCache;
