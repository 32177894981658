import { RouteRecordRaw } from 'vue-router';
import {HRouterView} from "@hview/hview-vue3/packages";

const form: Array<RouteRecordRaw> = [{
    path: '/form',
    component: HRouterView,
    children: [{
        path: "",
        meta: {
            title: "表单管理"
        },
        component: () => import("@/views/vform/list.vue")
    }, {
        path: ":id",
        meta: {
            title: "编辑表单"
        },
        component: () => import("@/views/vform/vform.vue")
    }, {
        path: "submit",
        meta: {
            title: "表单填写"
        },
        component: () => import("@/views/vform/submit.vue")
    }, {
        path: "submit-success",
        meta: {
            title: "填写成功"
        },
        component: () => import("@/views/vform/submit-success.vue")
    }, {
        path: "complete",
        meta: {
            title: "表单填写"
        },
        component: () => import("@/views/vform/complete-list.vue")
    }, {
        path: "result",
        meta: {
            title: "表单结果"
        },
        component: () => import("@/views/vform/form-result.vue")
    }]
}];
export default form;