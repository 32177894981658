"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "sub-menu-arrow" };
var vue_3 = require("vue");
var h_menu_item_vue_1 = require("./h-menu-item.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'h-menu',
    props: {
        menuList: {},
        active: {}
    },
    setup: function (__props) {
        var props = __props;
        var isFold = (0, vue_3.ref)(false);
        var showMenu = (0, vue_3.ref)(true);
        var foldEvent = function () {
            isFold.value = !isFold.value;
            if (isFold.value) {
                showMenu.value = false;
            }
            else {
                setTimeout(function () {
                    showMenu.value = true;
                }, 180);
            }
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                class: (0, vue_2.normalizeClass)(["sub-menu", { 'retract': (0, vue_2.unref)(isFold) }])
            }, [
                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(_ctx.menuList, function (item, index) {
                    return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, {
                        key: item.id
                    }, [
                        ((0, vue_2.unref)(showMenu))
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(h_menu_item_vue_1.default, {
                                key: 0,
                                menu: item,
                                active: _ctx.active
                            }, null, 8, ["menu", "active"]))
                            : (0, vue_2.createCommentVNode)("", true)
                    ], 64));
                }), 128)),
                (0, vue_2.createElementVNode)("div", _hoisted_1, [
                    (0, vue_2.createElementVNode)("div", {
                        class: "fold",
                        onClick: foldEvent
                    }, [
                        (0, vue_2.createElementVNode)("i", {
                            class: (0, vue_2.normalizeClass)(["icon iconfont icon-icon_jiantou", { 'rotate': !(0, vue_2.unref)(showMenu) }])
                        }, null, 2)
                    ])
                ])
            ], 2));
        };
    }
});
