"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
var application_1 = require("@config/application");
var user_1 = require("@hview/hview-utils/lib/cache/user");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: {
        modelValue: {}
    },
    emits: ["update:modelValue"],
    setup: function (__props, _a) {
        var __emit = _a.emit;
        var emits = __emit;
        var imageUrl = (0, vue_3.ref)("");
        var action = "".concat((0, application_1.getApiPath)(), "/api/common/upload");
        var uploadData = (0, vue_3.ref)({});
        var headers = (0, vue_3.ref)({
            token: user_1.default.getToken(),
            uid: user_1.default.getUserInfo().id || 0
        });
        var handleAvatarSuccess = function (response, uploadFile) {
            uploadData.value = response.data;
            emits("update:modelValue", response.data.url);
            imageUrl.value = URL.createObjectURL(uploadFile.raw);
        };
        return function (_ctx, _cache) {
            var _component_el_upload = (0, vue_2.resolveComponent)("el-upload");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_upload, {
                "on-success": handleAvatarSuccess,
                headers: headers.value,
                action: action,
                "show-file-list": false
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (imageUrl.value)
                        ? (0, vue_2.renderSlot)(_ctx.$slots, "file", {
                            key: 0,
                            file: uploadData.value
                        })
                        : (0, vue_2.renderSlot)(_ctx.$slots, "default", { key: 1 })
                ]; }),
                _: 3
            }, 8, ["headers"]));
        };
    }
});
