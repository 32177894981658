"use strict";
/**
 * @description:cookie
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.remove = exports.get = exports.set = void 0;
/**
 * @description:设置cookie值
 * @param key:键
 * @param value:值
 * @param minutes:存储的时间，单位为分钟(默认24小时)
 * @author zhaxianhe
 */
var set = function (key, value, minutes) {
    if (minutes === void 0) { minutes = 24 * 60; }
    var exp = new Date();
    exp.setTime(exp.getTime() + minutes * 60 * 1000);
    document.cookie = key + '=' + escape(value) + ';expires=' + exp.toGMTString() + ';path=/';
};
exports.set = set;
/**
 * @description: 获取cookie值
 * @param key:键
 * @return String
 * @author zhaxianhe
 */
var get = function (key) {
    var reg = new RegExp('(^| )' + key + '=([^;]*)(;|$)');
    var arr = document.cookie.match(reg);
    if (arr) {
        return unescape(arr[2]);
    }
    return null;
};
exports.get = get;
/**
 * @description:删除cookie值
 * @param key:键
 * @author zhaxianhe
 */
var remove = function (key) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var value = (0, exports.get)(key);
    if (value !== null) {
        document.cookie = key + '=' + value + ';expires=' + exp.toGMTString();
    }
};
exports.remove = remove;
