<template>
  <el-drawer class="el-drawer-custom" v-model="visible" :show-close="false" size="600">
    <template #header="{ titleId, titleClass }">
      <h4 :id="titleId" :class="titleClass">选择商品【已选:{{selectUserList.length}}】</h4>
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </template>
    <el-form ref="formRef" class="p-10" :model="model" inline>
      <el-form-item label="商品名称" prop="name">
        <el-input v-model="model.name" placeholder="请输入名称"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="load">查询</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="select-user">
      <div class="flex-1">
        <el-table v-loading="loading" :data="list" stripe style="width: 100%">
          <el-table-column fixed="left" prop="selected" label="选择" header-align="center" align="center" width="60">
            <template #default="scope">
              <el-checkbox @change="userChecked(scope.row)" v-model="scope.row.selected" :checked="isChecked(scope.row)"/>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="用户ID" header-align="center" align="center" width="100" />
          <el-table-column prop="title" label="商品名称" header-align="center" align="center" width="120"/>
          <el-table-column prop="image" label="商品图片" header-align="center" align="center" width="180">
            <template #default="scope">
              <img class="w-40 h-40" :src="getImagePath(scope.row.image)"/>
            </template>
          </el-table-column>
          <el-table-column prop="original_price" label="原价" header-align="center" align="center" width="120" />
          <el-table-column prop="price" label="实际价格" header-align="center" align="center" width="120" />

        </el-table>
      </div>
      <div class="footer">
        <div class="h-pagination">
          <h-pagination @change="paginationChangeEvent" :current-page="page" :total="totalRow" />
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script lang="ts" setup name="select-goods-drawer">
import {ref, defineExpose, defineEmits} from "vue";
import {usePagination, useImage} from "@hview/hview-vue3/packages";
const {updatePage, getPagination, totalRow, update, page, reload} = usePagination();

import {useApp} from "@hview/hview-vue3/packages/hooks";
import {getGoodsListApi} from "@/api/kh";

const {getImagePath} = useImage();

const formRef = ref();

/**
 * @description emit枚举
 */
enum EmitEnum {
  /**
   * 确认
   */
  CONFIRM = "confirm"
}
const emits = defineEmits<{
  confirm : void
}>();

/**
 * 表单数据
 */
const model = ref({

});

/**
 * 数据列表
 */
const list = ref([]);

/**
 * 选中的用户
 */
const selectUserList = ref([]);

const {visible, loading} = useApp();

/**
 * @description 重置
 */
const resetForm = () => {
  formRef.value.resetFields();
  reload(load);
}

/**
 * @description 加载数据
 */
const load = () => {
  loading.value = true;
  getGoodsListApi({...model.value,...getPagination()}).then((res: any) => {
    const data = res.data;
    updatePage(data);
    list.value = data.data;
  }).finally(() => {
    loading.value = false;
  });
}

/**
 * @description 派发事件
 */
const confirm = () => {
  emits(EmitEnum.CONFIRM, selectUserList.value);
  visible.value = false;
}

/**
 * @description 是否选中
 */
const isChecked = (data: any) => {
  if (selectUserList.value.indexOf(data.id) != -1) {
    data.selected = true;
    return true;
  }
  data.selected = false;

  return false;
}


/**
 * @description 页码改变事件
 */
const paginationChangeEvent = (value: number) => {
  update(value, load);
}

/**
 * @description 打开弹窗
 */
const open = (userlist: any) => {
  selectUserList.value = userlist;
  visible.value = true;
  load();
}

/**
 * @description 点击checkbox触发
 * @param data
 */
const userChecked = (data: any) => {
  data.selected = !data.selected;
  if (data.selected == true) {
    selectUserList.value.push(data.id);
  } else {
    const index = selectUserList.value.indexOf(data.id);
    selectUserList.value.splice(index, 1);
  }
}

defineExpose({
  open
});
</script>

<style lang="scss">
.el-drawer-custom {
  .el-drawer__body {
    padding: 20px 0 0 0 !important;
    .select-user {
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .footer {
        height: 72px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        box-shadow: 0 -4px 8px 0 rgba(203,206,216,0.16);
      }
    }
  }
}
</style>