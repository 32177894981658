<template>
  <h-container isBack isTable title="领取记录">
    <el-table :loading="loading" :data="list" stripe>
      <el-table-column prop="id" label="编号" header-align="center" align="center" width="100" />
      <el-table-column prop="user_id" label="用户ID" header-align="center" align="center" width="70" />
      <el-table-column prop="realname" label="用户姓名" align="center" header-align="center" width="180">
        <template #default="scope">
          <h-text v-model="scope.row.realname"/>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="用户昵称" align="center" header-align="center" width="180">
        <template #default="scope">
          <h-text v-model="scope.row.nickname"/>
        </template>
      </el-table-column>
      <el-table-column prop="limit" label="张数" header-align="center" align="center" width="70" />
      <el-table-column prop="usetime" label="使用时间" align="center" header-align="center" width="180">
        <template #default="scope">
          {{scope.row.usetime}}
          <span v-if="scope.row.usetime">{{timeFormat(scope.row.usetime)}}</span>
          <span v-else>未使用</span>
        </template>
      </el-table-column>
      <el-table-column prop="usetimeend" label="有效期" align="center" header-align="center" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.usetimeend)}}
        </template>
      </el-table-column>
      <el-table-column prop="stock" label="订单号" align="center" header-align="center" width="180">
        <template #default="scope">
          <span v-if="scope.row.use_order_id && scope.row.use_order_id != 0">
            <h-text v-model="scope.row.use_order_id"/>
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="h-pagination">
        <h-pagination @change="paginationChangeEvent" :current-page="page" :total="totalRow" />
      </div>
    </template>
  </h-container>
</template>

<script lang="ts" setup>
import {ref, onMounted} from "vue";
import {useRoute} from "vue-router";
import {usePagination, useTimeFormat} from "@hview/hview-vue3/packages";
import {adminGetUserReceivecouponsListApi} from "@/api/kh";
import {useApp} from "@hview/hview-vue3/packages/hooks/use-app";
const {loading} = useApp();
const {timeFormat} = useTimeFormat();

const {updatePage, getPagination, totalRow, update, page} = usePagination();
const list = ref();

const route = useRoute();

const model = ref({
  id: ""
});

/**
 * @description 页码改变事件
 */
const paginationChangeEvent = (value: number) => {
  update(value, load);
}

const load = () => {
  loading.value = true;
  adminGetUserReceivecouponsListApi({...model.value, ...getPagination()}).then((res: any) => {
    const data = res.data;
    updatePage(data);
    list.value = data.data;
  }).finally(() => {
    loading.value = false;
  });
}

onMounted(() => {
  const {query} = route;
  model.value.CouponsId = query.id;
  load();
});
</script>

<style scoped>

</style>